'use strict';

document.addEventListener('DOMContentLoaded', function() {

  /**
   * Internet Explorer閲覧非推奨（モーダル表示）
   * @variable ua ユーザーエージェント
   * @variable browserMessage 表示HTML
   */
  const ua = window.navigator.userAgent;
  if (ua.indexOf('MSIE') != -1 || ua.indexOf('Trident') != -1) {
    const browserMessage = '当サイトを閲覧頂きありがとうございます。<br>当サイトのコンテンツはお客さまがご使用のブラウザには対応しておりません。<br>「Microsoft Edge」「Google Chrome」「Safari」の最新版ブラウザをご利用下さい。';
    const browserMessageHtml = '<div class="m-browserOverlay"></div><div class="m-browser"><div class="m-browser_container"><div class="m-browser_message">' + browserMessage +'</div></div></div>';
    document.querySelector('body').insertAdjacentHTML('afterbegin', browserMessageHtml);
    document.querySelector('html').style.overflow = 'hidden';
  }


  /**
   * パラメータ取得関数
   * @argument name 取得したいパラメータ名
   * @argument url 表示ページ以外のURLから取得したい場合
   */
  function getParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }


  /**
   * アンカースクロール
   * @variable speed スクロール速度
   */

  $('a[href^="#"]').on('click', function (){
    const speed = 1000;
    const href = $(this).attr('href');
    const target = $(href == '#' || href == '' ? 'html' : href);
    if ( target.offset() ) {
      const position = target.offset().top;
      $('html').animate({scrollTop: position}, speed, 'swing');
    }
    return false;
  });


  /**
   * Media Query Javascript
   */
  const mediaQuery = window.matchMedia('(min-width: 769px)')
  handleMediaQuery(mediaQuery)
  mediaQuery.addListener(handleMediaQuery)

  function handleMediaQuery(e) {
    if (e.matches) {
      // min-width: 769px (PC)


    } else {
      // max-width: 768px (SP)

    }
  }


  /**
   * スマートフォン表示でブラウザUIを考慮する処理
   * @memo 下記CSSを全画面表示したい要素に設定する
   * @memo min-height: 100vh; min-height: calc(var(--vh, 1vh) * 100);
   */
  const setFillHeight = function() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
  }

  // 画面のサイズ変動があった時に高さを再計算する
  window.addEventListener('resize', setFillHeight);

  // 初期化
  setFillHeight();


  // よくあるご質問
  $('.index-faq_list_item_q').on('click', function() {
    $(this).next('.index-faq_list_item_a').slideToggle();
    $(this).toggleClass('is-open');
  });

  $('.l-navTrigger').on('click', function() {
    $(this).toggleClass('is-open');
    $('.l-nav').toggleClass('is-open');
  });

  // ナビゲーション
  $('.l-nav_list_anchor').on('click', function() {
    $('.l-navTrigger').toggleClass('is-open');
    $('.l-nav').toggleClass('is-open');
  });

  // インビューアニメーション
  $('.js-inview').one('inview', function(event, isInView) {
    if (isInView) {
      $(this).stop().addClass('is-inview');
    }
  });


  /**
   * カレンダーのカレント処理
   */
  const today = dayjs(dayjs().format('YYYY-MM-DD 00:00:00')); // 現在の日付情報を取得
  let $todayItem = '';
  // time設定さしている
  $('.index-calendar_term').each(function(index, element) {
    const currentDatetime = $(this).children('time').attr('datetime');

    if ( today.diff(currentDatetime) <= 0 ) {
      $todayItem = $(element);
      return false;
    }
  });

  if ( $todayItem.length ) {
    $('.index-calendar_list').scrollTop($todayItem.offset().top - $('.index-calendar_list').offset().top + 2);
    $todayItem.css('border-top', '2px dashed #FFBB00');
  }

});
